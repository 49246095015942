$(document).ready(function() {	
	
	var he1 = $('.un_f1').find('ul').height();
	$('.un_f1').height("280px");
	
	$('body').on('click', '.un_f2', function(){
		var he = $('.un_f1').find('#found-categories').height();
		
		$(".un_f1").animate({height: ($('.un_f1').height() > 280 ? 280-10 : he+20)}, 'slow', function(){ 
			if($('.un_f1').height() > 280){
				$('.un_f2').html('<i class="fa fa-angle-up"></i> Cвернуть</div>');
			}else{
				$('.un_f2').html('<i class="fa fa-angle-down"></i> Показать все ('+$('.un_f2').attr('data-count')+')</div>');
			}
		});
	});
	
	$('body').on('click', '.un_f6', function(){
		var he = $('.product-properties').find('div.product-properties-table').height() + $('.product-properties').find('div.product-description-short').height();
		
		$(".product-properties").animate({height: ($('.product-properties').height() > 313 ? 314-1 : he)}, 'slow', function(){ 
			if($('.product-properties').height() > 313){
				$('.un_f6').text("Свернуть");
				
			}else{
				$('.un_f6').text("ПОКАЗАТЬ ВСЕ ПАРАМЕТРЫ");
			

			}
		});
	});
	
	$('body').on('click', '.un_f14', function(){
		var btn = $(this);
		var he = $(this).closest('.relatedescription').find('.relatedescription-annotation').find('ul').height();
		
		$(this).closest('.relatedescription').find(".relatedescription-annotation").animate({height: ($(this).closest('.relatedescription').find('.relatedescription-annotation').height() > 50 ? 51-1 : he)}, 'slow', function(){ 
			if($(this).closest('.relatedescription').find('.relatedescription-annotation').height() > 50){
				btn.text("Свернуть");
			}else{
				btn.text("Подробнее");
			}
		});
		
		return false;
	});

	$('body').on('click', '.un_f11', function(){
		var he = $('.kits-extras').find('ul.kits-extras-items').height() + 60;
		
		$(".kits-extras").animate({height: ($('.kits-extras').height() > 476 ? 475 : he+20)}, 'slow', function(){ 
			if($('.kits-extras').height() > 476){
				$('.un_f11').html('<div class="fa fa-angle-up"></div><div class="f2"> свернуть</div>');
				
			}else{
				$('.un_f11').html('<div class="fa fa-angle-down"></div><div class="f2"> показать все</div>');
			

			}
		});
	});
	
	setTimeout(function(){ 
		$('.kits-options-items input').change(function() {
			$('#popover601174').fadeOut('slow');
		});
		
		$('.kits-extras-block input').change(function() {
			$('#popover601175').fadeOut('slow');
		});
	}, 3000);
	/*$('.form-control').focus(function(){
		$('.order-stat').hide();
        $('#search').animate({ width:"586px"}, 2000);
    }).blur(function(){
        $('#search').animate({ width:"373px"}, 500, function(){ 
			$('.order-stat').show('slow');
		});
    });
	*/
	/*
    if ( window.location.pathname != '/' ){
		var wrap = $(".wrap.menu-blue");
		$(window).scroll(function () {
			
			if ($(this).scrollTop() > 147){
				wrap.addClass("un_f4");
				$('.order-stat').hide();
				$('.un_f12').show();
				
			} else {
				wrap.removeClass("un_f4");
				$('.un_f12').hide();
				$('.order-stat').show();
				
			}
		});
	}
    */
	$('.popup-marker').popover({
        html: true,
        trigger: 'manual'
    });
	un_f7();
	
});

function un_f7(){
	$('.popup-marker').each(function(e){
		
			$(this).popover('show');
	 
	})
}